<template>
  <nav class="m-menu">
    <button type="button" class="m-menu__background" @click="closeBurgerMenu"></button>
    <div class="m-menu__container">
      <button type="button" class="m-menu__close" @click="closeBurgerMenu">
        <IconComponent category="default" name="close" />
      </button>
      <ul class="m-menu__links">
        <li>
          <span>
            <span @click="closeBurgerMenu">
              <router-link :to="{ name: 'ministry-about' }">{{ departmentTitle }}</router-link>
            </span>
            <button type="button" @click="toggleMenu(0)">
              <IconComponent category="vuesax-bold" name="arrow-down" />
            </button>
          </span>
          <ul v-if="activeMenu === 0">
            <li @click="closeBurgerMenu">
              <router-link :to="{ name: 'ministry-about' }">Общая информация</router-link>
            </li>
            <li @click="closeBurgerMenu">
              <router-link :to="{ name: 'ministry-management' }">Руководство</router-link>
            </li>
            <li @click="closeBurgerMenu">
              <router-link :to="{ name: 'ministry-structure' }">Структура</router-link>
            </li>
            <li v-if="!types_limitation.includes(system_type.code)" @click="closeBurgerMenu">
              <router-link :to="{ name: 'ministry-departments' }"> Подведомственные учреждения </router-link>
            </li>
            <li @click="closeBurgerMenu">
              <router-link :to="{ name: 'ministry-goals' }">Цели и задачи</router-link>
            </li>
            <li v-for="(activity, i) in about_activities" :key="i" @click="closeBurgerMenu">
              <router-link :to="{ name: 'ministry-activity-item', params: { id: activity.id } }">
                {{ activity.title }}
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <span>
            <span @click="closeBurgerMenu">
              <router-link :to="{ name: 'activity' }">Деятельность</router-link>
            </span>
            <button type="button" @click="toggleMenu(1)" v-if="activities.length">
              <IconComponent category="vuesax-bold" name="arrow-down" />
            </button>
          </span>
          <ul v-if="activities.length && activeMenu === 1">
            <li v-for="(activity, i) in activities" :key="i" @click="closeBurgerMenu">
              <router-link :to="{ name: 'activity-item', params: { id: activity.id } }">
                {{ activity.title }}
              </router-link>
            </li>
          </ul>
        </li>
        <li @click="closeBurgerMenu">
          <router-link :to="{ name: 'documents' }">Документы</router-link>
        </li>
        <li>
          <span @click="closeBurgerMenu">
            <router-link :to="{ name: 'press-list' }">Пресс-центр</router-link>
            <button type="button" @click="toggleMenu(2)" v-if="news_categories.length">
              <IconComponent category="vuesax-bold" name="arrow-down" />
            </button>
          </span>
          <ul v-if="news_categories.length && activeMenu === 2">
            <li v-for="(type, i) in news_categories" :key="i" @click="closeBurgerMenu">
              <router-link :to="type.to">
                {{ type.title }}
              </router-link>
            </li>
          </ul>
        </li>
        <li @click="closeBurgerMenu">
          <router-link :to="{ name: 'contacts' }" @click="closeBurgerMenu">Контакты</router-link>
        </li>
        <li @click="closeBurgerMenu">
          <router-link :to="{ name: 'appeals-form' }" @click="closeBurgerMenu">
            Обращения граждан
          </router-link>
        </li>
      </ul>
      <div class="m-menu__footer">
        <!--        <ul class="m-menu__footer-list">-->
        <!--          <li>-->
        <!--            <a class="top-nav__link" href="https://glava.e-dag.ru/" target="_blank" rel="nofollow">-->
        <!--              Глава РД-->
        <!--            </a>-->
        <!--          </li>-->
        <!--          <li>-->
        <!--            <a class="top-nav__link" href="http://e-dag.ru/" target="_blank" rel="nofollow">-->
        <!--              Правительство РД-->
        <!--            </a>-->
        <!--          </li>-->
        <!--        </ul>-->
        <div @click="closeBurgerMenu" class="m-menu__footer-buttons">
          <router-link :to="{ name: 'search' }" class="m-menu__footer-link m-menu__footer-link--big">
            <IconComponent category="default" name="search" />
            <span>Поиск</span>
          </router-link>
          <a
            v-if="$store.state.department.phone"
            title="Номер телефона"
            :href="`tel:${$store.state.department.phone}`"
            class="m-menu__footer-link m-menu__footer-link--big"
          >
            <IconComponent category="vuesax-bold" name="call" />
            <span>{{ $store.state.department.phone }}</span>
          </a>
          <div @click="closeBurgerMenu" class="m-menu__footer-link m-menu__footer-link--big bvi-open">
            <IconComponent category="vuesax-bold" name="glass-1" />
            <span>Версия для слабовидящих</span>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "MobileMenuComponent",
  inject: ["types_limitation"],
  data() {
    return {
      activeMenu: null,
    };
  },
  mounted() {
    require(["bvi"], ({ Bvi }) => {
      new Bvi();
    });
  },
  computed: {
    types() {
      return this.$store.state._types;
    },
    system_type() {
      return this.$store.state.department ? this.$store.state.department.system_type : {};
    },
    departmentTitle() {
      if (this.system_type.code) {
        return this.system_type.name;
      }
      return "Министерство";
    },
    about_activities() {
      return this.$store.state.activities.filter((a) => a.type.code === 2);
    },
    activities() {
      return this.$store.state.activities.filter((a) => a.type.code === 1);
    },
    news_categories() {
      return this.$store.state.news_categories.map((c) => ({
        title: c.title,
        to: {
          name: "press-list",
          query: {
            category: [c.id],
          },
        },
      }));
    },
  },
  watch: {
    "$route.name"() {
      this.$store.state.showMenu = false;
    },
  },
  methods: {
    closeBurgerMenu() {
      document.body.classList.remove("no-overflow");
      this.$store.state.showMenu = false;
    },
    toggleMenu(i) {
      if (this.activeMenu === i) {
        this.activeMenu = null;
        return;
      }
      this.activeMenu = i;
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/components/m-menu.styl"
</style>
