<template>
  <section class="global-alert" v-if="showAlert">
    <div class="global-alert__close" @click="hideAlert">&times;</div>
    <EditorJSComponent v-if="alertText" :text="JSON.parse(alertText)" />
  </section>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "GlobalAlertComponent",
  components: {
    EditorJSComponent,
  },
  mounted() {
    if (this.alertText) {
      let alertText = JSON.parse(this.alertText).blocks;

      if (this.alertText !== localStorage.alertText) {
        localStorage.showAlert = "";
        localStorage.alertText = this.alertText;
        this.showAlert = true;
      }
      if (localStorage.alertText && !localStorage.showAlert) {
        this.showAlert = true;

        if (!alertText.length) {
          this.showAlert = false;
          localStorage.alertText = "";
        }
      }
    }
  },
  data() {
    return {
      showAlert: false,
    };
  },
  methods: {
    hideAlert() {
      this.showAlert = false;
      localStorage.showAlert = false;
      localStorage.alertText = this.alertText;
    },
  },
  computed: {
    alertText() {
      return this.$store.state.department.alert_text;
    },
  },
};
</script>

<style lang="stylus">
.global-alert {
  position relative
  display flex
  padding 10px 30px
  background-color #f54e5a
  flex-direction: row-reverse;

  &__close {
    position absolute
    transform scale(2)
    color var(--white)
    cursor pointer
  }

  .article {
    > p {
      color var(--white)
      margin 0
      text-align center
      line-height 20px

      a {
        text-decoration underline
        color var(--white)
        transition var(--transition)
      }

      &:hover {
        a {
          color var(--dark)
        }
      }
    }
  }
}
</style>
