export default {
  namespaced: true,
  state: () => ({
    workers: [],
    sections: [],
    workers_item: null,
    sub_departments: [],
    subdepartment_categories: [],
    sub_departments_item: null,
    news_paginate: {
      data: [],
      paginatorInfo: {
        total: 0,
      },
    },
    ministry_breadcrumbs_title: null,
    ministry_more_workers: {
      data: [],
    },
  }),
  actions: {
    save({ state, rootState }, data) {
      if (data) {
        Object.keys(data).forEach((key) => {
          state[key] = data[key];
        });
      }
      if (data.department) {
        rootState.department = data.department;
      }
      if (data.activities) {
        rootState.activities = data.activities;
      }
      if (data.sections) {
        rootState.sections = data.sections;
      }
      if (data.categories) {
        rootState.news_categories = data.categories;
      }
      if (data.minister) {
        rootState.minister = data.minister;
      }
      if (data.document_types) {
        rootState.document_types = data.document_types;
      }
    },
  },
};
