<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="footer__left" v-if="department">
        <router-link :to="{ name: 'home' }" class="footer__logo">
          <IconComponent category="default" name="logo-white" />
          <pre>{{ department.title }}</pre>
        </router-link>
        <a v-if="department.old_url" class="footer__site" target="_blank" :href="department.old_url"
          >Старый сайт</a
        >
        <div class="footer__info">
          <span class="footer__info-copyrights"> © {{ year }}. {{ department.title }}</span>
          <a href="https://comrades.dev/" target="_blank">
            <span class="footer__info-comrades">Разработано Comrades Devs</span>
          </a>
        </div>
      </div>
      <div class="footer__right">
        <div class="footer__menu">
          <span class="footer__menu-title">{{ departmentAbout }}</span>
          <ul class="footer__menu-list">
            <li>
              <router-link :to="{ name: 'ministry-about' }">{{ departmentTitle }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'activity' }">Деятельность</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'documents' }">Документы</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'press-list' }">Пресс-центр</router-link>
            </li>
          </ul>
        </div>
        <div class="footer__menu">
          <span class="footer__menu-title">Обратная связь</span>
          <ul class="footer__menu-list">
            <li>
              <router-link :to="{ name: 'appeals-form' }">Обращения граждан</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'contacts' }">Контакты</router-link>
            </li>
            <li>
              <IconComponent category="default" name="age" />
            </li>
          </ul>
        </div>
        <div class="footer__menu footer__menu--wide" v-if="department">
          <span class="footer__menu-title">Контакты</span>
          <div class="footer__contacts">
            <div>
              <div class="footer__contacts-links">
                <a v-if="department.phone" :href="`tel:${department.phone}`">
                  Телефон: {{ department.phone }}
                </a>
                <a v-if="department.fax" :href="`tel:${department.fax}`">Факс: {{ department.fax }}</a>
                <a v-if="department.email" :href="`mailto:${department.email}`">
                  E-mail: {{ department.email }}
                </a>
              </div>
              <pre v-if="department.schedule">{{ department.schedule }}</pre>
            </div>
            <div>
              <pre v-if="department.address">{{ department.address }}</pre>
              <div class="footer__contacts-socials" v-if="department.socials">
                <a :href="link.link" target="_blank" v-for="(link, i) in department.socials" :key="i">
                  <IconComponent category="default" :name="link.type.code | socialIconName" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import moment from "moment";

export default {
  name: "FooterComponent",
  mounted() {},
  computed: {
    system_type() {
      return this.$store.state.department ? this.$store.state.department.system_type : {};
    },
    departmentTitle() {
      if (this.system_type.code) {
        return this.system_type.name;
      }
      return "Министерство";
    },
    departmentAbout() {
      switch (this.system_type.code) {
        case this.$store.state._types.SYSTEM_DEPARTMENT: {
          return "Об управлении";
        }
        case this.$store.state._types.SYSTEM_COMITET: {
          return "О комитете";
        }
        case this.$store.state._types.SYSTEM_INSPECTION: {
          return "Об инспекции";
        }
        case this.$store.state._types.SYSTEM_AGENCY: {
          return "Об агентстве";
        }
        case this.$store.state._types.SYSTEM_UPOLNOMOCH: {
          return "Об Уполномоченном";
        }
        case this.$store.state._types.SYSTEM_SERVICE: {
          return "О службе";
        }
        case this.$store.state._types.SYSTEM_SOVET: {
          return "О совете";
        }
        case this.$store.state._types.SYSTEM_VEDOMSTVO: {
          return "О ведомстве";
        }
        case this.$store.state._types.SYSTEM_ORGANISATION: {
          return "Об организации";
        }
        case this.$store.state._types.SYSTEM_UCHRED: {
          return "Об учреждении";
        }
      }
      return "О министерстве";
    },
    department() {
      return this.$store.state.department;
    },
  },
  data() {
    return {
      year: moment().format("YYYY"),
    };
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.footer {
  display flex
  justify-content center
  align-items center
  background var(--main)
  padding 50px 30px 55px
  +below(1280px) {
    padding 30px
  }

  &__container {
    max-width var(--max-width)
    width: 100%;
    display grid
    grid-template-columns 4fr 8fr
    grid-gap 30px
    align-items center
    +below(1280px) {
      display flex
      flex-direction column-reverse
      gap 30px
    }
  }

  &__left {
    display: grid;
    align-content space-between
    height 100%
    +below(1280px) {
      display flex
      flex-direction column
      grid-gap 15px
      width 100%
    }
  }

  &__logo {
    display grid
    grid-gap 30px
    +below(1280px) {
      grid-gap 10px
    }

    pre {
      font-weight: 700;
      font-size: 1.5em;
      line-height: 39px;
      color var(--white)
      +below(1280px) {
        font-size 0.75em
        line-height: 18px;
      }
    }

    .icon {
      width 186px
      height: 186px;
      +below(1280px) {
        width 64px
        height 64px
      }
    }
  }

  &__site {
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color var(--white)
    margin 10px 0 10px 0
    text-decoration underline
  }

  &__info {
    display flex
    flex-direction column
    gap 3px
    &-copyrights {
      font-weight: 500;
      font-size: 0.875em
      line-height: 22px;
      color: var(--white);
      +below(1280px) {
        font-size: 0.75em;
        line-height: 14px;
      }
    }
    &-comrades {
      font-size 0.75em
      font-weight 400
      line-height 26px
      color var(--white)
      +below(1280px) {
        font-size: 0.625em;
        line-height: 14px;
      }
    }
  }

  &__right {
    display grid
    align-items start
    grid-template-columns 1fr 1fr
    grid-gap 30px
    +below(1280px) {
      grid-template-columns 1fr
      width: 100%;
    }
  }

  &__menu {
    display grid
    align-content start
    .default-age-icon{
      width: 50px;
      height: 50px;
    }

    &--wide {
      grid-column 1 / -1
      +below(1280px) {
        grid-template-columns 1fr
        grid-column 1
      }
    }
  }

  &__menu-title {
    font-weight: 500;
    font-size: 1.375em;
    line-height: 26px;
    color: var(--white);
    padding-bottom 20px
    margin-bottom 20px
    border-bottom 1px solid var(--gray-dark-o3)
    +below(1280px) {
      font-size: 1.125em;
      line-height: 22px;
    }
  }

  &__menu-list {
    display grid
    grid-gap 15px
    font-weight: 400;
    font-size: 1em;
    line-height: 15px;
    +below(1280px) {
      grid-gap 10px
    }

    li {
      display inline-flex

      a {
        text-decoration-line: underline;
        color: var(--white);
        +below(1280px) {
          font-size: 0.875em;
          line-height: 22px;
        }

        &.router-link-active {
          opacity: 0.5;
        }
      }
    }
  }

  &__contacts {
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: var(--white);
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 30px
    +below(1280px) {
      grid-template-columns 1fr
      font-size: 0.875em;
      line-height: 22px;
    }

    > * {
      display grid
      grid-gap 30px
      align-content start
    }

    pre {
      font-weight: 500;
      font-size: 1em
      line-height: 26px;
      color: var(--white);
      white-space pre-line
      +below(1280px) {
        font-size: 0.875em;
        line-height: 22px;
      }
    }
  }

  &__contacts-links {
    display grid
    gap 10px
    +below(1280px) {
      gap 5px
    }

    a {
      font-weight: 500;
      font-size: 1em
      line-height: 26px;
      color: var(--white);
    }
  }

  &__contacts-socials {
    display flex
    flex-wrap wrap
    align-items flex-start
    gap 5px

    a {
      background: var(--gray);
      border: 1px solid var(--dark-o1);
      border-radius: 100px;
      width 50px
      height 50px
      display flex
      align-items center
      justify-content center

      .icon {
        max-width 22px
        max-height: 22px

        svg path {
          fill var(--main)
        }
      }
    }
  }
}
</style>
