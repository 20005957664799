import Vue from "vue";
import moment from "moment";
import { VueMaskFilter } from "v-mask";
import plural from "plural-ru";
import petrovich from "petrovich";
import punycode from "punycode/";

Vue.filter("formatDate", (date) => {
  return moment(date).format("DD.MM.YYYY");
});

Vue.filter("socialIconName", (code) => {
  if (code === 1) return "tg";
  if (code === 2) return "wp";
  if (code === 3) return "vk";
  if (code === 4) return "ok";
  if (code === 5) return "tw";
  if (code === 6) return "yt";
  if (code === 7) return "tt";
  if (code === 8) return "rt";
  if (code === 9) return "dz";
  return "";
});

Vue.filter("humanDate", (val) => {
  if (val) {
    moment.locale("ru");
    const date = moment(val);
    if (date) {
      const weekDay = date.format("dddd").toLowerCase();
      const day = date.format("DD");
      const month = petrovich(
        {
          gender: "male",
          first: date.format("MMMM").toLowerCase(),
        },
        "genitive"
      ).first;
      const year = date.format("YYYY");

      return `${weekDay}, ${day} ${month} ${year} г.`;
    }
  }
  return "";
});

Vue.filter("humanDateShort", (val, time) => {
  if (val) {
    moment.locale("ru");
    const date = moment(val);
    if (date) {
      const day = date.format("DD");
      const month = petrovich(
        {
          gender: "male",
          first: date.format("MMMM").toLowerCase(),
        },
        "genitive"
      ).first;
      const year = date.format("YYYY");
      if (time) {
        const hour = date.format("HH");
        const minute = date.format("mm");
        return `${day} ${month} ${year}, ${hour}:${minute}`;
      }
      return `${day} ${month} ${year} г.`;
    }
  }
  return "";
});

Vue.filter("robotDate", (date) => {
  return moment(date);
});

Vue.filter("getArticle", (item, name = "description") => {
  try {
    let json = JSON.parse(item[name]);
    if (typeof json === "string") {
      json = JSON.parse(json);
    }
    if (typeof json === "string") {
      json = JSON.parse(json);
    }
    if (json.blocks) {
      return json;
    }
    return {
      blocks: [
        {
          type: "paragraph",
          data: { text: item[name] },
        },
      ],
    };
  } catch {
    return {
      blocks: [
        {
          type: "paragraph",
          data: { text: item[name] },
        },
      ],
    };
  }
});

Vue.filter("url_domain", (v) => {
  try {
    new URL(v);
  } catch {
    return "";
  }
  return punycode.toUnicode(new URL(v).hostname);
});

Vue.filter("plural", (count, ...words) => {
  return plural(count, ...words.map((w) => "%d " + w));
});

Vue.filter("plural_word", (count, ...words) => {
  return plural(count, ...words.map((w) => w));
});

Vue.filter("vMask", VueMaskFilter);

Vue.filter("two_digit_number", (number) => {
  return number.toLocaleString("ru-RU", { minimumIntegerDigits: 2, useGrouping: false });
});

/**
 * Проверка на наличие текста в EditorJs
 * если это текст от EditorJs и там пусто - false
 * undefined/null/'null' - false
 * любой другой текст - true
 */
Vue.filter("editor_has_text", (text) => {
  try {
    let parsedText = JSON.parse(text);
    if (typeof parsedText === "object") {
      return !!(parsedText && parsedText.blocks && parsedText.blocks.length);
    }
    return !!text;
  } catch (e) {
    if (typeof text === "string") {
      return !!text;
    }
    return false;
  }
});
