<template>
  <div id="app">
    <vue-progress-bar />
    <GlobalAlertComponent />
    <TopNavigator v-if="hide" />
    <HeaderComponent v-if="hide" />
    <Transition mode="out-in" name="page">
      <RouterView :key="$route.fullPath" />
    </Transition>
    <FooterComponent v-if="hide" />
    <MobileMenuComponent v-if="$store.state.showMenu" />
    <SmallMenuComponent v-if="$store.state.smallMenu.length" />
    <ClientOnly>
      <Notifications position="bottom right" />
    </ClientOnly>
    <ModalsComponent />
    <PhoneButtonComponent />
    <AppealsButtonComponent />
    <CookiesComponent />
  </div>
</template>

<script>
import GlobalAlertComponent from "components/GlobalAlertComponent.vue";
import TopNavigator from "components/navigation/TopNavigator.vue";
import HeaderComponent from "components/navigation/HeaderComponent.vue";
import MobileMenuComponent from "components/navigation/MobileMenuComponent.vue";
import FooterComponent from "components/navigation/FooterComponent.vue";
import SmallMenuComponent from "components/navigation/SmallMenuComponent.vue";
import ClientOnly from "vue-client-only";
import ModalsComponent from "components/modals/index.vue";
import CookiesComponent from "components/CookiesComponent.vue";
import PhoneButtonComponent from "components/PhoneButtonComponent.vue";
import { computed } from "vue";
import AppealsButtonComponent from "components/AppealsButtonComponent.vue";

export default {
  name: "App",
  metaInfo() {
    const meta = this.$store.state.department?.title;
    return {
      titleTemplate: `%s | ${meta}`,
    };
  },
  computed: {
    types() {
      return this.$store.state._types;
    },
  },
  provide() {
    return {
      types_limitation: computed(() => [
        this.$store.state._types.SYSTEM_UPOLNOMOCH,
        this.$store.state._types.SYSTEM_COMITET,
        this.$store.state._types.SYSTEM_INSPECTION,
      ]),
    };
  },
  created() {
    // Полоска загрузки
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$nextTick().then(() => {
        this.$Progress.finish();
      });
    });
  },
  mounted() {
    this.$Progress.finish();
    this.hide = true;
    if (this.$route.name === "portal" || this.$route.name === "portalOpen") {
      this.hide = false;
    }
  },
  data() {
    return {
      hide: true,
    };
  },
  components: {
    AppealsButtonComponent,
    PhoneButtonComponent,
    CookiesComponent,
    GlobalAlertComponent,
    ModalsComponent,
    ClientOnly,
    SmallMenuComponent,
    FooterComponent,
    MobileMenuComponent,
    HeaderComponent,
    TopNavigator,
  },
};
</script>

<style lang="stylus">
@import 'styles/style.styl';
</style>
