<template>
  <div class="search-modal">
    <div class="search-modal__field">
      <IconComponent category="default" name="search" />
      <input
        v-model="search"
        class="search-modal__input"
        placeholder="Поиск по сайту"
        type="text"
        @input="handleInput"
        autofocus
      />
      <span v-if="loading" class="search-modal__loading">
        <IconComponent category="default" name="loading" />
      </span>
      <span v-if="results.data.length" class="search-modal__count">
        {{ results.paginatorInfo.total }}
      </span>
      <button v-if="!!search.length" @click="clearInput" class="search-modal__close" type="button">
        <IconComponent category="default" name="close" />
      </button>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "SearchModalComponent",
  data() {
    return {
      loading: false,
      search: "",
      results: {
        data: [],
      },
      inputDebounceTimeout: null,
    };
  },
  methods: {
    clearInput() {
      this.search = "";
      this.handleInput();
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.search-modal {
  background: var(--white);
  border-radius: 15px;
  max-width: 710px;
  width 100%
  z-index 2
  box-shadow: 0 16px 24px var(--black_o06), 0px 2px 6px var(--black_o04), 0px 0px 1px var(--black_o04);

  &__loading {
    display flex
    flex-shrink 0

    .icon {
      display: flex;
      width: 30px;
      height: 30px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__close {
    background none
    border none
    cursor pointer
    display: flex;
    flex-shrink 0
    align-items center
    justify-content center
  }

  &__results {
    display flex
    flex-direction column
    gap 13px
    margin 0 20px
    padding 20px 0
    border-top 1px solid var(--border_color)
  }

  &__result {
    font-weight: normal;
    font-size: 1.125em;
    line-height: 22px;
    color: var(--black);
    display grid
    align-items center
    grid-template-columns 30px 1fr
    text-decoration none
    grid-gap 15px
  }

  &__not-found {
    display flex
    margin 0 20px
    border-top 1px solid var(--border_color)
    padding 50px 10px
    flex-direction column
    justify-content center
    align-items center
    gap 30px
    font-weight: normal;
    font-size: 1.125em;
    line-height: 22px;
    color: var(--black);

    .icon {
      width: 300px;
      height: 200px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__field {
    display flex
    align-items center
    padding 20px
    gap 15px

    .icon {
      display: flex;
      width: 30px;
      height: 30px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__count {
    background: var(--main_color);
    border-radius: 5px;
    width 25px
    height 25px
    flex-shrink 0
    font-weight: 500;
    font-size: 0.75em;
    line-height: 15px;
    text-align: center;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__input {
    border none
    box-sizing border-box
    background none
    width 100%
    font-family var(--font_regular)
    font-size: 1.125em;
    line-height: 19px;
    color var(--black)
    appearance none
    z-index 1
    outline none
    height: 50px;
    margin: -10px 0;

    &::placeholder {
      color var(--black)
    }
  }
}
</style>
