export default {
  namespaced: true,
  state: () => ({
    document_types: [],
    acts_item: null,
    acts_paginate: {
      data: [],
      paginatorInfo: {
        total: 0,
      },
    },
  }),
  actions: {
    save({ state, rootState }, data) {
      if (data) {
        Object.keys(data).forEach((key) => {
          state[key] = data[key];
        });
      }
      if (data.department) {
        rootState.department = data.department;
      }
      if (data.categories) {
        rootState.news_categories = data.categories;
      }
      if (data.activities) {
        rootState.activities = data.activities;
      }
      if (data.sections) {
        rootState.sections = data.sections;
      }
      if (data.document_types) {
        rootState.document_types = data.document_types;
      }
    },
  },
};
