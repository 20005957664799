<template>
  <nav class="top-nav">
    <div class="top-nav__container">
      <ol class="top-nav__links">
        <li>
          <a class="top-nav__link" href="https://glava.e-dag.ru/" target="_blank" rel="nofollow">
            Глава РД
          </a>
        </li>
        <li>
          <a class="top-nav__link" href="http://e-dag.ru/" target="_blank" rel="nofollow">
            Правительство РД
          </a>
        </li>
        <li v-if="department.id === 19">
          <a
            class="top-nav__link"
            href="https://daggji.e-dag.ru/activity/10232"
            target="_blank"
            rel="nofollow"
          >
            Досудебное обжалование
          </a>
        </li>
        <li v-if="department.id === 19">
          <a
            class="top-nav__link"
            href="https://daggji.e-dag.ru/ministry/activity/11603"
            target="_blank"
            rel="nofollow"
          >
            Клиентоцентричность
          </a>
        </li>
        <li v-if="department.id === 53">
          <a class="top-nav__link" href="https://ombudsmanrf.org/" target="_blank" rel="nofollow">
            Уполномоченный по правам человека в РФ
          </a>
        </li>
      </ol>
      <div class="top-nav__socials" v-if="department.socials">
        <a :href="link.link" target="_blank" v-for="(link, i) in department.socials" :key="i">
          <IconComponent category="default" :name="link.type.code | socialIconName" />
        </a>
      </div>
      <ol class="top-nav__buttons">
        <li>
          <router-link :to="{ name: 'search' }" class="top-nav__link top-nav__link--bold top-nav__link--sm-p">
            <IconComponent category="default" name="search" />
          </router-link>
        </li>
        <li v-if="department.phone">
          <a
            v-if="department.phone"
            title="Номер телефона"
            :href="`tel:${department.phone}`"
            class="top-nav__link top-nav__link--sm-p top-nav__link--bold top-nav__link--big"
          >
            <IconComponent category="vuesax-bold" name="call" />
            <!--            <span>{{ $store.state.department.phone }}</span>-->
          </a>
        </li>
        <li>
          <router-link
            :to="{ name: 'appeals-form' }"
            class="top-nav__link top-nav__link--bold top-nav__link--sm-p"
          >
            <IconComponent category="vuesax-bold" name="sms" />
          </router-link>
        </li>
        <li>
          <a class="top-nav__link top-nav__link--bold top-nav__link--sm-p bvi-open">
            <IconComponent category="vuesax-bold" name="glass-1" />
          </a>
        </li>
      </ol>
    </div>
  </nav>
</template>
<script>
import IconComponent from "components/IconComponent.vue";
import SearchModal from "components/modals/components/SearchModal.vue";

export default {
  name: "TopNavigator",
  mounted() {
    require(["bvi"], ({ Bvi }) => {
      new Bvi();
    });
  },
  computed: {
    department() {
      return this.$store.state.department || {};
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.top-nav {
  display flex
  align-items center
  justify-content center
  width 100%
  background var(--main)
  padding 5px 30px
  +below(1280px) {
    display none
  }

  &__container {
    display flex
    align-items center
    justify-content space-between
    max-width var(--max-width)
    width 100%
  }

  &__links {
    display flex
    align-items center
    justify-content flex-start
    margin 0 -16px

    li {
      display: inline-flex;
    }
  }

  &__buttons {
    display flex
    justify-content flex-end
    align-items center
    margin -8px

    li {
      display inline-flex
    }
  }

  &__link {
    font-weight: 400;
    font-size: 0.875em;
    line-height: 17px;
    color: var(--white);
    padding 16px
    display inline-flex
    align-items center
    gap 5px

    .icon {
      width 50px
      height 50px
      background: var(--main);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 100px;

      svg {
        width 20px
        height 20px

        path {
          fill var(--white)
        }
      }
    }

    &--sm-p {
      padding 8px
    }

    &--bold {
      font-weight 500
    }

    &--big {
      font-size 1em;
    }
  }

  &__socials {
    display flex
    align-items center
    gap 20px

    .icon {
      max-width 22px
      max-height: 22px

      svg path {
        fill var(--white)
      }
    }
  }
}
</style>
