<template>
  <nav class="m-menu">
    <button type="button" class="m-menu__background" @click="$store.state.smallMenu = []"></button>
    <div class="m-menu__container">
      <button type="button" class="m-menu__close" @click="$store.state.smallMenu = []">
        <IconComponent category="default" name="close" />
      </button>
      <ul class="m-menu__links">
        <li v-for="(link, i) in $store.state.smallMenu" :key="i">
          <router-link :to="link.to">{{ link.title }}</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "SmallMenuComponent",
  data() {
    return {
      activeMenu: null,
    };
  },
  methods: {
    toggleMenu(i) {
      if (this.activeMenu === i) {
        this.activeMenu = null;
        return;
      }
      this.activeMenu = i;
    },
  },
  watch: {
    "$route.name"() {
      this.$store.state.smallMenu = [];
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/components/m-menu.styl"
</style>
