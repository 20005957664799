import Vue from "vue";
import Vuex from "vuex";

// modules
import home from "./modules/home.js";
import documents from "./modules/documents.js";
import news from "./modules/news.js";
import ministry from "./modules/ministry.js";
import activity from "./modules/activity.js";
import portal from "./modules/portal.js";

Vue.use(Vuex);
export default function createStore(env) {
  return new Vuex.Store({
    modules: {
      home,
      documents,
      ministry,
      news,
      activity,
      portal,
    },
    state() {
      return {
        user: null,
        showMenu: false,
        smallMenu: [],
        department: null,
        minister: null,
        faqs: [],
        activities: [],
        news_categories: [],
        document_types: [],
        sections: [],
        _env: env,
        _modals: [],
        _types: {
          // Коды системы
          SYSTEM_DEPARTMENT: 1, // Управление
          SYSTEM_COMITET: 2, // Комитет
          SYSTEM_INSPECTION: 3, // Инспекция
          SYSTEM_AGENCY: 4, // Агентство
          SYSTEM_UPOLNOMOCH: 5, // Уполномоченный
          SYSTEM_SERVICE: 6, // Служба
          SYSTEM_SOVET: 7, // Совет
          SYSTEM_MINISTERSTVO: 8, // Министерство
          SYSTEM_VEDOMSTVO: 9, // Ведомство
          SYSTEM_ORGANISATION: 10, // Организация
          SYSTEM_UCHRED: 11, // Учреждение
          // Типы счетчика просмотра
          COUNTER_NEWS_TYPE: 1,
          COUNTER_ACTIVITY_TYPE: 2,
          COUNTER_DOCUMENT_TYPE: 3,
        },
      };
    },
    actions: {
      save({ state, rootState }, data) {
        if (data) {
          Object.keys(data).forEach((key) => {
            state[key] = data[key];
          });
        }
        if (data.categories) {
          rootState.news_categories = data.categories;
        }
      },
    },
  });
}
