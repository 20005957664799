import "es6-promise/auto";
import createApp from "./app";

let env = window.__INITIAL_STATE__._env || {};
if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {
  env.MEDIA_ENDPOINT = "https://" + location.hostname;
  env.APOLLO_ENDPOINT = "https://" + location.hostname + "/graphql";
}
const { app, router, store, apollo } = createApp(env);

if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__);
  store.state._window_size = window.innerWidth;
  store.state._env = env || {};
  store.state.apollo_token = window.__INITIAL_STATE__.apollo_token || undefined;
}

router.onReady(() => {
  router.beforeResolve((to, from, next) => {
    const matched = router.getMatchedComponents(to);
    const asyncDataHooks = matched.map((c) => c.asyncData).filter((_) => _);
    if (!asyncDataHooks.length) {
      return next();
    }
    return Promise.all(asyncDataHooks.map((hook) => hook({ store, route: to, apollo })))
      .then(() => {
        next();
      })
      .catch(next);
  });
  app.$mount("#app");
});

if (location.protocol === "https:" && navigator.serviceWorker) {
  navigator.serviceWorker.register("/service-worker.js").then((value) => {
    console.log(value);
  });
}
