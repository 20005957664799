import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("./views/home/index.vue"),
    },
    {
      path: "/contacts",
      name: "contacts",
      component: () => import("./views/contacts/index.vue"),
    },
    {
      path: "/search",
      name: "search",
      component: () => import("./views/search/index.vue"),
    },
    {
      path: "/press",
      name: "press-list",
      component: () => import("./views/press/index.vue"),
    },
    {
      path: "/press/:id",
      name: "press-item",
      component: () => import("./views/press/open.vue"),
    },
    {
      path: "/documents",
      name: "documents",
      component: () => import("./views/documents/index.vue"),
    },
    {
      path: "/documents/:id",
      name: "document",
      component: () => import("./views/documents/open.vue"),
    },
    {
      path: "/activity",
      name: "activity",
      component: () => import("./views/activity/index.vue"),
    },
    {
      path: "/activity/:id",
      name: "activity-item",
      component: () => import("./views/activity/open.vue"),
    },
    {
      path: "/ministry",
      name: "ministry",
      component: () => import("./views/ministry/index.vue"),
      children: [
        {
          path: "about",
          name: "ministry-about",
          redirect: { name: "ministry-management" },
          component: () => import("./views/ministry/about/index.vue"),
        },
        {
          path: "management",
          name: "ministry-management",
          // meta: {
          //   management: true,
          //   structure: false,
          // },
          component: () => import("./views/ministry/management/index.vue"),
        },
        {
          path: "structure",
          name: "ministry-structure",
          // meta: {
          //   management: false,
          //   structure: true,
          // },
          component: () => import("./views/ministry/structure/index.vue"),
        },
        {
          path: "management/:id",
          name: "ministry-management-open",
          component: () => import("./views/ministry/management/open.vue"),
          meta: {
            links: [{ title: "Руководство", to: { name: "ministry-management" } }],
          },
        },
        {
          path: "departments",
          name: "ministry-departments",
          component: () => import("./views/ministry/departments/index.vue"),
        },
        {
          path: "departments/:id",
          name: "ministry-departments-open",
          component: () => import("./views/ministry/departments/open.vue"),
          meta: {
            links: [{ title: "Подведомственные учреждения", to: { name: "ministry-departments" } }],
          },
        },
        {
          path: "goals",
          name: "ministry-goals",
          component: () => import("./views/ministry/goals/index.vue"),
        },
        {
          path: "activity/:id",
          name: "ministry-activity-item",
          component: () => import("./views/ministry/activity/index.vue"),
        },
      ],
    },
    {
      path: "/appeals",
      name: "appeals",
      component: () => import("./views/appeals/index.vue"),
      children: [
        {
          path: "about",
          name: "appeals-about",
          component: () => import("./views/appeals/about/index.vue"),
        },
        {
          path: "faq",
          name: "appeals-faq",
          component: () => import("./views/appeals/faq/index.vue"),
        },
        {
          path: "form",
          name: "appeals-form",
          component: () => import("./views/appeals/form/index.vue"),
        },
        {
          path: "posts/:id?",
          name: "appeals-posts",
          component: () => import("./views/appeals/posts/index.vue"),
        },
      ],
    },
    {
      path: "/portal",
      name: "portal",
      component: () => import("./views/portal/index.vue"),
    },
    {
      path: "/portal/:id",
      name: "portalOpen",
      component: () => import("./views/portal/open.vue"),
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("./views/PageNotFound.vue"),
    },
  ];
  return new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
        };
      }
      return { x: 0, y: 0 };
    },
    fallback: true,
    routes,
  });
}
