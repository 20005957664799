<template>
  <header class="header">
    <div class="header__container">
      <router-link :to="{ name: 'home' }" class="header__logo" v-if="department">
        <IconComponent category="default" name="logo-blue" />
        <pre>{{ department.title }}</pre>
      </router-link>
      <ol class="header__links">
        <li>
          <router-link :to="{ name: 'ministry-about' }">
            <span>{{ departmentTitle }}</span>
            <IconComponent category="vuesax-bold" name="arrow-down" />
          </router-link>
          <div class="header__submenu">
            <div class="header__submenu_container">
              <h2>{{ departmentTitle }}</h2>
              <ul v-if="system_type">
<!--                <li>-->
<!--                  <router-link :to="{ name: 'ministry-about' }">{{ departmentAbout }}</router-link>-->
<!--                </li>-->
                <li>
                  <router-link :to="{ name: 'ministry-management' }">Руководство</router-link>
                </li>
                <li v-if="!!this.$store.state.sections.filter((s) => s.workers.length).length">
                  <router-link :to="{ name: 'ministry-structure' }">Структура</router-link>
                </li>
                <li v-if="!types_limitation.includes(system_type.code)">
                  <router-link :to="{ name: 'ministry-departments' }">
                    Подведомственные учреждения
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'ministry-goals' }">Цели и задачи</router-link>
                </li>
                <li v-for="(activity, i) in about_activities" :key="i">
                  <router-link :to="{ name: 'ministry-activity-item', params: { id: activity.id } }">
                    {{ activity.title }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <router-link :to="{ name: 'activity' }">
            <span>Деятельность</span>
            <IconComponent category="vuesax-bold" name="arrow-down" v-if="activities.length" />
          </router-link>
          <div class="header__submenu">
            <div class="header__submenu_container">
              <h2>Деятельность</h2>
              <ul v-if="activities.length">
                <li v-for="(activity, i) in activities" :key="i">
                  <router-link :to="{ name: 'activity-item', params: { id: activity.id } }">
                    {{ activity.title }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <router-link :to="{ name: 'documents' }">
            <span>Документы</span>
            <IconComponent category="vuesax-bold" name="arrow-down" v-if="activities.length" />
          </router-link>
          <div class="header__submenu">
            <div class="header__submenu_container">
              <h2>Документы</h2>
              <ul v-if="documents_types.length">
                <li v-for="(type, i) in documents_types" :key="i">
                  <router-link :to="{ name: 'documents', query: { type: type.id } }">
                    {{ type.title }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <router-link :to="{ name: 'press-list' }">
            <span>Пресс-центр</span>
            <IconComponent category="vuesax-bold" name="arrow-down" v-if="news_categories.length" />
          </router-link>
          <div class="header__submenu">
            <div class="header__submenu_container">
              <h2>Пресс-центр</h2>
              <ul v-if="news_categories.length">
                <li v-for="(type, i) in news_categories" :key="i">
                  <router-link :to="type.to">
                    {{ type.title }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <router-link :to="{ name: 'contacts' }">Контакты</router-link>
        </li>
      </ol>
      <button type="button" class="header__m-menu" @click="openBurgerMenu">
        <IconComponent category="default" name="menu" />
      </button>
    </div>
  </header>
</template>
<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "HeaderComponent",
  inject: ["types_limitation"],
  computed: {
    news_categories() {
      return this.$store.state.news_categories.map((c) => ({
        title: c.title,
        to: {
          name: "press-list",
          query: {
            category: [c.id],
          },
        },
      }));
    },
    system_type() {
      return this.$store.state.department ? this.$store.state.department.system_type : {};
    },
    types() {
      return this.$store.state._types;
    },
    departmentTitle() {
      if (this.system_type.code) {
        return this.system_type.name;
      }
      return "Министерство";
    },
    departmentAbout() {
      switch (this.system_type.code) {
        case this.types.SYSTEM_DEPARTMENT: {
          return "Об управлении";
        }
        case this.types.SYSTEM_COMITET: {
          return "О комитете";
        }
        case this.types.SYSTEM_INSPECTION: {
          return "Об инспекции";
        }
        case this.types.SYSTEM_AGENCY: {
          return "Об агентстве";
        }
        case this.types.SYSTEM_UPOLNOMOCH: {
          return "Об Уполномоченном";
        }
        case this.types.SYSTEM_SERVICE: {
          return "О службе";
        }
        case this.types.SYSTEM_SOVET: {
          return "О совете";
        }
        case this.types.SYSTEM_VEDOMSTVO: {
          return "О ведомстве";
        }
        case this.$store.state._types.SYSTEM_ORGANISATION: {
          return "Об организации";
        }
        case this.$store.state._types.SYSTEM_UCHRED: {
          return "Об учреждении";
        }
      }
      return "О министерстве";
    },
    department() {
      return this.$store.state.department;
    },
    about_activities() {
      return this.$store.state.activities.filter((a) => a.type?.code === 2);
    },
    activities() {
      return this.$store.state.activities.filter((a) => a.type?.code === 1);
    },
    documents_types() {
      return this.$store.state.document_types;
    },
  },
  methods: {
    openBurgerMenu() {
      document.body.classList.add("no-overflow");
      this.$store.state.showMenu = true;
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.header {
  display flex
  justify-content center
  align-items center
  position relative
  padding 0 30px
  z-index: 2;

  &__container {
    max-width var(--max-width)
    width 100%
    display flex
    align-items stretch
    justify-content space-between
    gap 30px
    +below(1280px) {
      align-items center
    }
  }

  &__logo {
    padding 15px 0
    display inline-flex
    align-items center
    gap 22px
    font-weight: 700;
    font-size: 1.1em;
    line-height: 26px;
    color var(--dark)
    +below(1280px) {
      gap 10px
    }

    pre {
      margin 0
      max-width 350px
      +below(1280px) {
        font-size 0.875em
        line-height 19px
        white-space break-spaces
      }
    }

    .icon {
      width 95px
      height 95px
      +below(1280px) {
        width 61px
        height 61px
      }
      +below(768px) {
        display: none
      }
    }
  }

  &__m-menu {
    background none
    border none
    padding 15px
    flex-shrink 0
    margin -15px
    +above(1281px) {
      display none
    }

    .icon {
      width 31px
    }
  }

  &__links {
    display flex
    align-items stretch
    margin 0 -25px
    +below(1280px) {
      display none
    }

    li {
      display inline-flex

      &:hover a .icon {
        transform scaleY(-1)
      }

      &:hover .header__submenu {
        visibility visible
        opacity 1
      }

      a {
        display inline-flex
        align-items center
        justify-content center
        gap 10px
        padding 25px
        color var(--dark)

        .icon {
          width 18px
          height 18px

          svg path {
            fill var(--dark)
          }
        }
      }
    }
  }
  &__submenu_container {
    display flex
    width var(--max-width)
    margin 0 auto
    gap 300px

    h2 {
      min-width 200px
      margin 0
      color var(--dark)
    }

    ul {
      display grid
      width 100%
      grid-template-columns 1fr 1fr 1fr

      li {
        padding 0 15px
      }
    }
  }

  &__submenu {
    width 100%
    background: var(--white);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    clip-path: inset(0px -15px -35px -15px);
    display flex
    visibility hidden
    opacity 0
    transition var(--transition)
    //flex-wrap wrap
    //justify-content center
    //align-items center
    padding 10px 30px
    absolute left bottom right
    transform translateY(100%)

    &:hover {
      visibility visible
      opacity 1
    }

    li {
      display inline-flex

      a {
        padding 5px
        display inline-flex
        font-weight: 500;
        font-size: 0.875em;
        //line-height: 22px;
        color: var(--dark);
        border-radius 5px
        transition var(--transition)

        &:hover {
          background rgba(0, 0, 0, 0.02)
        }
      }
    }
  }
}
</style>
